import React from 'react';
import { get, isEmpty } from 'lodash';
import createLink from 'utils/createLink';

import { SearchMenu } from 'containers/menu/Search';
import { SocialContainer } from 'containers/social/Social';
import { MenuArticles } from 'containers/menu-articles/MenuArticles';
import { SearchArticles } from 'containers/search-articles/SearchArticles';
import { NavigationContext } from 'context/navigation';

import { FooterNav } from 'components/footer/FooterNav';
import { Link } from 'components/link/Link';
import { Menu } from 'components/menu/Menu';
import { Navigation } from 'components/menu/Navigation';
import { Callout } from 'components/search/Callout';
import RichText from 'components/rich-text/RichText';

interface ILink {
  link_title: any;
  navigation_link: any;
}

export const MenuContainer = ({ navigation: navProps }: any) => {
  const { navigation: navContext } = React.useContext<any>(NavigationContext);

  const navigation = isEmpty(navContext) ? navProps : navContext;

  const renderMainNavLink = (item: ILink) => {
    if (item === null || item.navigation_link === null) {
      return;
    }

    const title = item.link_title
      ? RichText.asText(item, 'link_title')
      : RichText.asText(item, 'navigation_link.menu_label') ||
        RichText.asText(item, 'navigation_link.title');

    const key =
      item.navigation_link._meta && item.navigation_link._meta.uid
        ? item.navigation_link._meta.uid
        : item.navigation_link.url;

    return (
      <Link key={key} to={createLink(item.navigation_link)}>
        {title}
      </Link>
    );
  };

  const renderFooterNavLink = ({ footer_nav_link: item }: any, i: number) => {
    if (item === null || item._meta === null ) { return; }

    return (
      <Link key={get(item, '_meta.uid', i)} to={createLink(item)}>
        {RichText.asText(item, 'title')}
      </Link>
    );
  };

  const mainNavTitle = get(navigation, 'main_navigation_title', []);
  const mainNav = get(navigation, 'main_navigation', []);
  const articleNavTitle = get(navigation, 'article_navigation_title', []);
  const articleNav = get(navigation, 'article_navigation', []);
  const footerNav = get(navigation, 'footer_navigation', []);
  const searchLinks = get(navigation, 'search_bottom_links', []);

  let searchMenu = null;

  if (Array.isArray(searchLinks) && searchLinks.length > 0) {
    const randomIndex = Math.floor(Math.random() * searchLinks.length);

    const calloutTitle = RichText.asText(searchLinks[randomIndex], 'search_bottom_links_title');
    const calloutLink = createLink(get(searchLinks[randomIndex], 'search_bottom_links_link'));

    searchMenu = (
      <SearchMenu
        articles={<SearchArticles title={RichText.asText(articleNavTitle)} data={articleNav} />}
        cta={<Callout title={calloutTitle} link={calloutLink} />}
      />
    );
  }

  return (
    <Menu>
      <Navigation
        heading={RichText.asText(mainNavTitle)}
        footer={
          <>
            <SocialContainer
              type="menu"
              facebookLink={createLink(get(navigation, 'facebook_link', null))}
              twitterLink={createLink(get(navigation, 'twitter_link', null))}
              instagramLink={createLink(get(navigation, 'instagram_link', null))}
              youtubeLink={createLink(get(navigation, 'youtube_link', null))}
              pinterestLink={createLink(get(navigation, 'pinterest_link', null))}
            />
            <FooterNav type="menu">
              {footerNav.map(renderFooterNavLink)}
            </FooterNav>
          </>
        }
      >
        {mainNav.map(renderMainNavLink)}
      </Navigation>

      {searchMenu}

    </Menu>
    );
};
