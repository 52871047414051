import React from 'react';

import s from './SignUpForm.scss';

interface IProps {
  label: string;
  placeholder?: string;
}

export const SignUpForm = ({ label }: IProps) => (
  <div className={s('signUpForm')}>
    <a
      rel="noreferrer"
      href="https://us5.list-manage.com/subscribe?u=659c762c292b90d37062a5974&id=16c6a03ce0"
      target="_blank"
      className={s.signUpForm__label}
    >
      {label}
    </a>
  </div>
);
