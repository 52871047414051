import React, { useContext, useRef, useState, useEffect } from 'react';
import Helmet from 'react-helmet';

import { useKeyDown } from 'hooks/use-keydown';
import { UIContext } from 'context/ui';

import s from './Menu.scss';

interface IProps {
  children: React.ReactNode;
}

export const Menu = ({ children }: IProps) => {

  const { searchOpen, menuOpen, closeAllMenus } = useContext<any>(UIContext);
  const menu = useRef<HTMLDivElement>(null);
  const [tabIndex, setTabIndex] = useState(-1);
  const keys = useKeyDown();

  const onOpen = () => {
    menu.current!.style.overflow = menuOpen ? 'auto' : 'visible';
  };

  if (menuOpen && keys[0] === 27) { closeAllMenus(); }

  useEffect(() => {
    if (!menu.current) { return; }
    menu.current.addEventListener('transitionend', onOpen);

    setTabIndex(menuOpen ? 0 : -1);

    return () => {
      if (!menu.current) { return; }
      menu.current.removeEventListener('transitionend', onOpen);
    };
  }, [menuOpen]);

  return (
    <>
      {menuOpen && (<Helmet htmlAttributes={{ class: 'scroll-disabled' }} />)}
      <div className={s('menu', { open: menuOpen, searchOpen })}>
        <button tabIndex={tabIndex} className={s.menu__backdrop} onClick={closeAllMenus} />
        <div className={s.menu__inner} ref={menu}>
          <div className={s.menu__content}>
            {children}
          </div>
        </div>
      </div>
      </>
    );
};
