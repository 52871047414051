// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js": () => import("./../node_modules/gatsby-source-prismic-graphql/components/PreviewPage.js" /* webpackChunkName: "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js" */),
  "component---src-pages-elements-tsx": () => import("./../src/pages/elements.tsx" /* webpackChunkName: "component---src-pages-elements-tsx" */),
  "component---src-pages-kranavatn-download-tsx": () => import("./../src/pages/kranavatn/download.tsx" /* webpackChunkName: "component---src-pages-kranavatn-download-tsx" */),
  "component---src-pages-kranavatn-index-tsx": () => import("./../src/pages/kranavatn/index.tsx" /* webpackChunkName: "component---src-pages-kranavatn-index-tsx" */),
  "component---src-pages-kranavatn-partners-tsx": () => import("./../src/pages/kranavatn/partners.tsx" /* webpackChunkName: "component---src-pages-kranavatn-partners-tsx" */),
  "component---src-templates-about-iceland-tsx": () => import("./../src/templates/AboutIceland.tsx" /* webpackChunkName: "component---src-templates-about-iceland-tsx" */),
  "component---src-templates-article-tsx": () => import("./../src/templates/Article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-festivals-tsx": () => import("./../src/templates/Festivals.tsx" /* webpackChunkName: "component---src-templates-festivals-tsx" */),
  "component---src-templates-flights-to-iceland-tsx": () => import("./../src/templates/FlightsToIceland.tsx" /* webpackChunkName: "component---src-templates-flights-to-iceland-tsx" */),
  "component---src-templates-home-tsx": () => import("./../src/templates/Home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-icelandic-pledge-tsx": () => import("./../src/templates/IcelandicPledge.tsx" /* webpackChunkName: "component---src-templates-icelandic-pledge-tsx" */),
  "component---src-templates-plan-your-trip-tsx": () => import("./../src/templates/PlanYourTrip.tsx" /* webpackChunkName: "component---src-templates-plan-your-trip-tsx" */),
  "component---src-templates-regions-of-iceland-tsx": () => import("./../src/templates/RegionsOfIceland.tsx" /* webpackChunkName: "component---src-templates-regions-of-iceland-tsx" */),
  "component---src-templates-search-details-tsx": () => import("./../src/templates/searchDetails.tsx" /* webpackChunkName: "component---src-templates-search-details-tsx" */),
  "component---src-templates-things-to-do-tsx": () => import("./../src/templates/ThingsToDo.tsx" /* webpackChunkName: "component---src-templates-things-to-do-tsx" */)
}

