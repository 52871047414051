import React, { cloneElement, Children, useContext } from 'react';
import { UIContext } from 'context/ui';
import { FormattedMessage } from 'react-intl';

import s from './Social.scss';

interface IProps {
  children: React.ReactNode;
  type?: 'menu' | 'footer' | 'sidebar';
  blended?: boolean;
}

export const Social = ({ children, type, blended }: IProps) => {

  const childs = Children.toArray(children);
  const { menuOpen } = useContext<any>(UIContext);

  return (
    <div className={s('social', type, { menuOpen, blended: blended && type === 'sidebar' })}>
      <div className={s.social__inner}>
        <ul className={s.social__list}>
          {Children.map(childs, (child: any, i) => {

            if (child === null || child.props.hideOnSidebar) { return null; }

            return (
              <li key={`${s.social}-${i}`} className={s.social__item}>
                {cloneElement(child as React.ReactElement<any>, {
                  className: s.social__link,
                })}
              </li>
            );
          })}
          {type === 'sidebar' && (
            <li className={s.social__label}><FormattedMessage id="share" /></li>
          )}
        </ul>
      </div>
    </div>
  );
};

Social.defaultProps = {
  type: 'sidebar',
  blended: true,
};
