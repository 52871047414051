import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { Alert } from 'components/alert/Alert';
import { Link } from 'components/link/Link';

import s from './Consent.scss';

interface IProps {
  moreLink?: string;
}

export const Consent = ({ moreLink }: IProps) => {
  const [visible, setVisible] = useState(false);

  const readCookie = () => {
    return document.cookie.includes('gdprConsent');
  };

  const setCookie = () => {

    if (!readCookie()) {
      const d = new Date();
      d.setFullYear(d.getFullYear() + 10);
      document.cookie = `gdprConsent=true; expires=${d}`;
    }

    setVisible(false);
  };

  useEffect(() => {
    setVisible(!readCookie());
  }, []);

  return (
    <Alert visible={visible}>
      <div className={s.consent}>
        <FormattedMessage id="cookies" />
      </div>
      <div className={s.consent__actions}>
        {moreLink && <Link className={s.consent__link} to={moreLink}><FormattedMessage id="privacy-policy" /></Link>}
        <button
          className={s.consent__button}
          onClick={() => { setCookie(); }}
        >
          <svg className={s.consent__check} viewBox="0 0 16 12">
            <path d="M2 6l4 4 8-8" />
          </svg>
          <FormattedMessage id="ok" />
        </button>
      </div>
    </Alert>
  );
};

Consent.defaultProps = {
  moreLinkText: 'Learn more',
  acceptButtonText: 'Okay',
};
