import * as React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { injectIntl } from 'react-intl';
import languages from 'localization/languages';

import { UIContext } from 'context/ui';

const LocalizedLink = ({ intl: { locale }, to, children, onClick: customOnClick, ...props }: any) => {
  const passProps = { ...props };
  const language = languages[locale];
  const path = to; //language && language.default ? to : `/${locale}${to}`;
  const isLink = (typeof to !== 'undefined');
  const isExternal = isLink && /^((https?:)?\/\/|[0-9a-zA-Z]+:)/.test(to || '');

  const { canTransition, setCanTransition, closeAllMenus } = React.useContext<any>(UIContext);

  const onClick = (e: any) => {
    let isPropagationStopped = false;

    // If a custom onClick handler doesn't want the link to do the transition stuff it should call
    // e.stopPropagation()
    if (customOnClick) {
      customOnClick(e);

      isPropagationStopped = e.isPropagationStopped && e.isPropagationStopped();
    }

    if (isPropagationStopped) {
      return;
    }

    closeAllMenus();
    if (canTransition) { return; }

    setCanTransition(true);
  };

  if (isExternal) {
    return <a target="_blank" rel="noopener noreferrer" href={to} onClick={onClick} {...passProps}>{children}</a>;
  }

  if (path === '') {
    return <a href="#" onClick={onClick} {...passProps}>{children}</a>;
  }

  return (
    <GatsbyLink
      {...props}
      to={path}
      onClick={onClick}
    >
      {children}
    </GatsbyLink>
  );
};

export const Link = injectIntl(LocalizedLink);
