import React from 'react';

import { Search } from 'components/search/Search';
import { MenuCard } from 'components/menu/MenuCard';
import { Results } from 'components/search/Results';
import { Categories, Category } from 'components/search/Categories';

interface IProps {
  isMenu: boolean;
  articles: React.ReactElement<any>;
  cta: React.ReactElement<any>;
}

export const SearchContainer = ({ isMenu, articles, cta }: IProps) => {

  return (
      <Search
        isMenu={isMenu}
        categories={(
          <Categories>
            <Category name="Activity" slug="activity" count={30} prismicId="XG6G9xUAAMLWFzIu" />
            <Category name="Culture" slug="culture" count={30} prismicId="XG6HSRUAAMLWFzOc" />
            <Category name="Destination" slug="destination" count={30} prismicId="XG6HOhUAAODWFzNa" />
            <Category name="Nature" slug="nature" count={30} prismicId="XG6G9xUAAMLWFzIu" />
            <Category name="Accomodation" slug="accomodation" count={30} prismicId="XG6G9xUAAMLWFzIu" />
            <Category name="Dining" slug="dining" count={30} prismicId="XG6G9xUAAMLWFzIu" />
            <Category name="Guide" slug="guide" count={30} prismicId="XG6G9xUAAMLWFzIu" />
          </Categories>
        )}
        articles={articles}
        cta={cta}
      >
        <Results companyCount={0} />
      </Search>
  );
};
