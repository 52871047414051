import React from 'react';

import s from './Container.scss';

interface IContainerProps {
  children: React.ReactNode;
  fluid?: boolean;
  className?: string;
}

export const Container = ({ children, fluid, className }: IContainerProps) => (
  <div className={s('container', className, { fluid })}>
    {children}
  </div>
);
