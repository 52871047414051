import React, { useContext } from 'react';
import { UIContext } from 'context/ui';

import { Container } from 'components/layout/Container';
import { Row } from 'components/layout/Row';
import { Segment } from 'components/layout/Segment';

import s from './Footer.scss';

interface IProps {
  form: React.ReactElement<any>;
  social: React.ReactElement<any>;
  navigation: React.ReactElement<any>;
  colophon: React.ReactElement<any>;
  copyright: string;
}

export const Footer = ({ navigation, form, copyright, colophon, social }: IProps) => {

  const { footerVisible } = useContext<any>(UIContext);

  if (!footerVisible) { return null; }

  return (
    <Segment>
      <footer className={s('footer')}>
        <Container>
          <Row>
            <div className={s.footer__navigation}>
              {navigation}
            </div>
            <div className={s.footer__form}>
              {form}
            </div>
          </Row>

          <div className={s.footer__colophon}>
            <div className={s.footer__copyright}>
              {copyright}
            </div>

            <div className={s.footer__colophonItem}>
              {colophon}
            </div>
            <div className={s.footer__social}>
              {social}
            </div>
          </div>
        </Container>
      </footer>
    </Segment>
  );
};
