import React, { Children } from 'react';
import { FormattedMessage } from 'react-intl';

import s from './Results.scss';

interface IProps {
  children?: React.ReactNode;
  articleCount: number;
  companyCount: number;
  onClear?: () => void;
  isLoading: boolean;
}

export const Results = ({ articleCount, companyCount, children, onClear, isLoading }: IProps) => {

  return (
    <div className={s.results}>

      {!isLoading ?
        (
          (articleCount > 0 || companyCount > 0) ? (
            <div className={s.results__summary}>
              <FormattedMessage id="found" />
              {articleCount > 0 && (<span className={s.results__count}>{articleCount} <FormattedMessage id="articles" /> </span>)}
              {(articleCount > 0 && companyCount > 0) && '/'}
              {companyCount > 0 && (<span className={s.results__count}> {companyCount} <FormattedMessage id="companies" /></span>)}
              <button className={s.results__clear} onClick={onClear}>Clear</button>
            </div>
          ) : (
            <div className={s.results__summary}>
              <FormattedMessage id="no-results" />
              <button className={s.results__clear} onClick={onClear}>Clear</button>
            </div>
          )
        ) :
        <div className={s.results__summary}>
          <FormattedMessage id="fetching-results" />...
        </div>
      }

      <ol className={s.results__list}>
        {Children.map(children, (child, i) => (
          <li className={s.results__item} key={`${s.results}-${i}`}>{child}</li>
        ))}
      </ol>
    </div>
  );
};
