import * as React from 'react';
import { debounce } from 'lodash';

interface ISizes {
  width: number;
  height: number;
}

export const useResize = () => {
  const [sizes, setSizes] = React.useState<ISizes>({ width: 0, height: 0 });
  const [isMobile, setMobile] = React.useState<boolean>(true);
  const [isDesktop, setDesktop] = React.useState<boolean>(true);

  const onResize = debounce(() => {
    if (typeof window === undefined) {
      return;
    }

    setSizes({ width: window.innerWidth, height: window.innerHeight });
    setMobile(!window.matchMedia('(min-width: 720px)').matches);
    setDesktop(window.matchMedia('(min-width: 1080px)').matches);
  }, 100);

  React.useEffect(() => {
    if (typeof window === undefined) {
      return;
    }

    onResize();
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return {
    isMobile,
    isDesktop,
    sizes,
  };
};
