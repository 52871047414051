import React, { useState } from 'react';

import { ViewportEnter } from 'components/viewport-enter/ViewportEnter';

import s from './PrismicImage.scss';

interface IProps {
  image: Prismic.IImageBlur;
  className?: string;
  view?: string;
  preserveRatio?: boolean; // where parent component handles image ratio
  relative?: boolean;
}

// Cache if we've seen an image before so we don't bother with
// lazy-loading & fading in on subsequent mounts.
const imageCache: any = {};
const inImageCache = (props: Prismic.IImage) => {
  const src = props.url;

  return imageCache[src] || false;
};

// Add image to cache
const activateCacheForImage = (props: Prismic.IImage) => {
  const src = props.url;

  imageCache[src] = true;
};

export const PrismicImage = ({
  image,
  className,
  view,
  preserveRatio,
  relative,
  ...rest
  }: IProps) => {

  const [isVisible, setIsVisible] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [seenBefore, setSeenBefore] = useState(false);

  const x1 = view ? image[`${view}_x1`] || image[view] : image;
  const x2 = view ? image[`${view}_x2`] || image[view] : undefined;
  const x3 = view ? image[`${view}_x3`] || image[view] : undefined;

  const base64 = view ? image[`${view}_base64`] || image.base64 : undefined ;

  const srcSet = x1 && x2 && x3 && `${x1.url} 1x, ${x2.url} 2x, ${x3.url} 3x` ||
    x1 && x2 && `${x1.url} 1x, ${x2.url} 2x` ||
    x1 && undefined;

  const aspectRatio = x1.dimensions.width / x1.dimensions.height;

  const isInCache = inImageCache(x1);

  const handleImageLoaded = () => {
    activateCacheForImage(x1);

    setIsLoaded(true);

    if (isInCache) {
      setSeenBefore(true);
    }
  };

  return (
    <div className={s(className, 'prismicImage', { visible: isLoaded || seenBefore } )}>
      <ViewportEnter onEnter={() => setIsVisible(true)} threshold={0}>
        <div className={s('prismicImage__inner', { relative })}>
          {preserveRatio && (
            <div
              className={s.prismicImage__aspect}
              style={{
                paddingBottom: `${100 / aspectRatio}%`,
              }}
            />
          )}

          {base64 && !seenBefore &&
            <img
              src={base64.url}
              alt=""
              className={s.prismicImage__blur}
            />
          }

          {isVisible &&
            <img
              src={x1.url}
              srcSet={srcSet}
              alt={image.alt || ''}
              width={x1.dimensions.width}
              height={x1.dimensions.height}
              className={s.prismicImage__image}
              onLoad={handleImageLoaded}
              {...rest}
            />
          }
        </div>
      </ViewportEnter>
    </div>
  );
};

PrismicImage.defaultProps = {
  preserveRatio: true,
  relative: true,
};
