import React, { useContext, useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { injectIntl } from 'react-intl';
import { LocaleContext } from 'context/localization';
import languages from 'localization/languages';

import { useKeyDown } from 'hooks/use-keydown';

import s from './LanguageButton.scss';

interface IProps {
  disabled: boolean;
  intl: {
    locale: string;
  };
}

export const LanguageButtonWithIntl = ({ disabled, intl: { locale } }: IProps) => {

  const [open, setOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(-1);
  const languageData = languages as any;
  const keys = useKeyDown();

  const { localizedPath } = useContext(LocaleContext);
  const currentLanguage: Localization.ILanguage = languageData[locale];
  const { display } = currentLanguage;

  const onLanguageChange = (value: any) => {
    const path: any = localizedPath(value, currentLanguage);

    if (path) {
      navigate(path);
    }
  };

  useEffect(() => {
    // on esc press
    if (open && keys.includes(27)) {
      setOpen(!open);
    }
  }, [keys]);

  useEffect(() => {
    setTabIndex(open ? 0 : -1);
  }, [open]);

  return (
    <div className={s('languageButton', { disabled, open })}>
      <button className={s.languageButton__current} onClick={() => { setOpen(!open); }}>
        <span className={s.languageButton__inner}>
          <span className={s.languageButton__text}>{display}</span>
        </span>
      </button>

      <ul className={s.languageButton__menu}>
        {/* Currently not using the languages from Prismic */}
        {/*Object.keys(languages).map((language) => (
          <li key={language}>
            <button
              value={language}
              onClick={() => { onLanguageChange(language); setOpen(false); }}
              className={s.languageButton__button}
            >
              {languageData[language].fullDisplay}
            </button>
          </li>
        )) */}
        <li>
          <a tabIndex={tabIndex} className={s.languageButton__button} href="https://de.visiticeland.com/">German</a>
          <a tabIndex={tabIndex} className={s.languageButton__button} href="https://dk.visiticeland.com/">Danish</a>
          <a tabIndex={tabIndex} className={s.languageButton__button} href="https://is.visiticeland.com/">Icelandic</a>
          <a tabIndex={tabIndex} className={s.languageButton__button} href="https://es.visiticeland.com/">Spanish</a>
          <a tabIndex={tabIndex} className={s.languageButton__button} href="https://fr.visiticeland.com/">French</a>
          <a tabIndex={tabIndex} className={s.languageButton__button} href="https://jp.visiticeland.com/">Japanese</a>
          <a tabIndex={tabIndex} className={s.languageButton__button} href="https://no.visiticeland.com/">Norwegian</a>
          <a tabIndex={tabIndex} className={s.languageButton__button} href="https://it.visiticeland.com/">Italian</a>
          <a tabIndex={tabIndex} className={s.languageButton__button} href="https://se.visiticeland.com/">Swedish</a>
          <a tabIndex={tabIndex} className={s.languageButton__button} href="https://nl.visiticeland.com/">Dutch</a>
          <a tabIndex={tabIndex} className={s.languageButton__button} href="https://cn.visiticeland.com/">Chinese</a>
          <a tabIndex={tabIndex} className={s.languageButton__button} href="https://ru.visiticeland.com/">Russian</a>
        </li>
      </ul>
    </div>
  );
};

export const LanguageButton = injectIntl(LanguageButtonWithIntl);
