import React, { useState, useEffect, useContext } from 'react';
import { UIContext } from 'context/ui';
import { useResize } from 'hooks/use-resize';
import { injectIntl } from 'react-intl';
import { navigate } from 'gatsby';

import { MenuContainer } from 'containers/menu/Menu';

import { Link } from 'components/link/Link';
import { Container } from 'components/layout/Container';
import { LanguageButton } from 'components/header/LanguageButton';
import { MenuButton } from 'components/header/MenuButton';
import { SearchButton } from 'components/header/SearchButton';

import Logo from 'assets/svg/logo-new.svg';

import s from './Header.scss';

interface IProps {
  visible?: boolean;
  children?: React.ReactNode;
  navigation: any;
  intl: {
    locale: string;
  };
}

const HeaderWithIntl = ({ children, intl: { locale }, navigation }: IProps) => {

  const [scrollbarWidth, updateScrollbarWidth] = useState(0);
  const resize = useResize();
  const {
    searchOpen,
    canTransition,
    setSearchOpen,
    navOpen,
    setNavOpen,
    menuOpen,
    closeAllMenus,
    headerTheme,
    headerVisible,
  } = useContext<any>(UIContext);

  const offsetStyle = navOpen && scrollbarWidth > 0 ? {transform: `translateX(-${scrollbarWidth}px`} : {};

  const onMenuClick = () => {
    if (menuOpen) {
      closeAllMenus();
    } else {
      setNavOpen(true);
    }
  };

  const onSearchClick = () => {
    if (searchOpen) {
      return;
    }
    setSearchOpen(true);
  };

  useEffect(() => {
    if (window === undefined) { return; }
    updateScrollbarWidth(window.innerWidth - document.documentElement.clientWidth);
  }, [resize.sizes.width]);

  // set via UI || via location in <AppLayout />
  if (!headerVisible) {
    return null;
  }

  const skipNavigation = () => {
    navigate('#content');
  };

  return (
    <>
    <header className={s('header', headerTheme, { open: menuOpen, navOpen, searchOpen, transition: canTransition })}>
    <button onClick={skipNavigation} className={s.header__skip}>skip navigation</button>
      <Container fluid>
        <div className={s.header__inner}>
          <Link tabIndex={0} to="/" className={s.header__logoWrap}>
            <Logo className={s.header__logo} />
          </Link>
          <div
            className={s.header__controls}
            style={offsetStyle}
          >
            <div className={s.header__button}><LanguageButton disabled={menuOpen} /></div>
            <div className={s.header__button}><SearchButton onClick={onSearchClick} disabled={menuOpen} /></div>
            <div className={s.header__button}><MenuButton onClick={onMenuClick} close={menuOpen} /></div>
            {children}
          </div>
        </div>
      </Container>
    </header>
    <MenuContainer navigation={navigation} />
    </>
  );
};

export const Header = injectIntl(HeaderWithIntl);
