import React from 'react';
import { get, isEmpty } from 'lodash';
import createLink from 'utils/createLink';
import { NavigationContext } from 'context/navigation';

import { Footer } from 'components/footer/Footer';
import { FooterNav } from 'components/footer/FooterNav';
import { Link } from 'components/link/Link';
import RichText from 'components/rich-text/RichText';
import { SignUpForm } from 'components/sign-up-form/SignUpForm';

import { SocialContainer } from 'containers/social/Social';

export const FooterContainer = ({ navigation: navProps }: any) => {
  const { navigation: navContext } = React.useContext<any>(NavigationContext);

  const navigation = isEmpty(navContext) ? navProps : navContext;

  const renderBottomLink = ({ footer_bottom_link: item, footer_bottom_link_title: title }: any, i: number) => {
    if (item === null) { return; }

    const renderedTitle = title
      ? RichText.asText(title)
      : RichText.asText(item, 'menu_label') || RichText.asText(item, 'title');

    const key =
      item._meta && item._meta.uid
        ? item._meta.uid
        : item.url;

    return (
      <Link key={key} to={createLink(item)}>
        {renderedTitle}
      </Link>
    );
  };

  const renderFooterNavLink = ({ footer_nav_link: item, footer_nav_title: title }: any, i: number) => {
    if (item === null || item._meta === undefined) { return; }

    const renderedTitle = title
      ? RichText.asText(title)
      : RichText.asText(item, 'menu_label') || RichText.asText(item, 'title');

    return (
      <Link key={`${get(item, '_meta.uid', i)}-${i}`} to={createLink(item)}>
        {renderedTitle}
      </Link>
    );
  };

  const newsletterTitle = get(navigation, 'footer_newsletter_title', []);
  const newsletterPlacholder = get(navigation, 'footer_newsletter_placeholder', []);
  const copyright = get(navigation, 'footer_copyright', []);
  const footerNav = get(navigation, 'footer_nav', []);
  const bottomLinks = get(navigation, 'footer_bottom_links', []);

  return (
    <Footer
      navigation={
        <FooterNav type="footer">
          {footerNav.map(renderFooterNavLink)}
        </FooterNav>
      }
      social={
        <SocialContainer
          type="footer"
          facebookLink={createLink(get(navigation, 'facebook_link', undefined))}
          twitterLink={createLink(get(navigation, 'twitter_link', undefined))}
          instagramLink={createLink(get(navigation, 'instagram_link', undefined))}
          youtubeLink={createLink(get(navigation, 'youtube_link', undefined))}
          pinterestLink={createLink(get(navigation, 'pinterest_link', undefined))}
        />
      }
      colophon={bottomLinks.map(renderBottomLink)}
      copyright={RichText.asText(copyright)}
      form={(
        <SignUpForm
          label={RichText.asText(newsletterTitle)}
          placeholder={RichText.asText(newsletterPlacholder)}
        />
      )}
    />
  );
};
