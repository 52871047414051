import React, { useContext, Children } from 'react';

import { SearchContent } from './SearchContent';

import s from './Articles.scss';

interface IProps {
  children: React.ReactNode;
  heading: string;
}

export const Articles = ({ children, heading }: IProps) => {

  return (
    <SearchContent heading={heading}>
      <ul className={s.articles}>
        {children && Children.map(children, (child, i) => (
          <li className={s.articles__item} key={`${s.articles}-${i}`}>{child}</li>
        ))}
      </ul>
    </SearchContent>
  );
};
