import React from 'react';
import { FormattedMessage } from 'react-intl';

import s from './SearchButton.scss';

interface IProps {
  onClick: () => void;
  disabled: boolean;
}

export const SearchButton = ({ onClick, disabled }: IProps) => {

  return (
    <button className={s('searchButton', { disabled })} onClick={onClick}>
      <svg viewBox="0 0 23 23" className={s.searchButton__icon}>
        <g transform="translate(1 1)">
          <path d="M20.5 20.5l-6.3-6.3"/>
          <circle cx="8.5" cy="8.5" r="8"/>
        </g>
      </svg>
      <FormattedMessage id="search" defaultMessage="Search">
        {(text) => <span className={s.searchButton__inner}>{text}</span>}
      </FormattedMessage>
    </button>
  );
};
