import React, { useContext } from 'react';

import { UIContext } from 'context/ui';

import s from './SearchContent.scss';

interface IProps {
  children: React.ReactNode;
  heading: string;
  theme: 'dark' | 'light';
  transition?: boolean;
}

export const SearchContent = ({ children, theme, heading, transition }: IProps) => {

  const { menuOpen } = useContext<any>(UIContext);

  return (
    <section className={s('searchContent', theme, { transition, open: menuOpen })}>
      <div className={s.searchContent__headingWrap}>
        <h1 className={s.searchContent__heading}>{heading}</h1>
      </div>
      <div className={s.searchContent__content}>
        {children}
      </div>
    </section>
  );
};
