import React, { cloneElement } from 'react';

import { Link } from 'components/link/Link';
import { Row } from 'components/layout/Row';

import s from './SearchCard.scss';

interface IProps {
  heading: string;
  category: string;
  image?: React.ReactElement<any>;
  link: string;
}

export const SearchCard = ({ category, heading, image, link }: IProps) => {

  return (
    <div className={s.searchCard}>
      <Row>
        <div className={s.searchCard__col}>
          <div className={s.searchCard__imageWrap}>
            {image && cloneElement(image, {
              className: s.searchCard__image,
            })}
          </div>
        </div>

        <div className={s.searchCard__col}>
          <div className={s.searchCard__content}>
            {category && (<h3 className={s.searchCard__kicker}>{category}</h3>)}
            <h2 className={s.searchCard__heading}>
              <Link className={s.searchCard__link} to={link}>{heading}</Link>
            </h2>
            </div>
          </div>
        </Row>
    </div>
  );
};
