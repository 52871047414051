import { fixRelative } from './linkResolver';

const targetLanguage = process.env.GATSBY_TARGET_LANGUAGE;

export default function createLink(doc: any, localize: boolean = false) {

  if (doc === null || doc === undefined) {
    // console.warn('Doc is null', __typename);
    return '/';
  }

  const { __typename, _meta = {}, url } = doc;
  const { uid = '', lang = '' } = _meta;
  let langPrefix = localize && lang && lang !== 'en-us' ? `/${lang}` : '';

  if (targetLanguage) {
    langPrefix = '';
  }

  if (__typename) {
    switch (__typename) {
      case 'PRISMIC_Page_content':
        return `${langPrefix}/page/${uid}`;
      case 'PRISMIC_Article':
        return `${langPrefix}/article/${uid}`;
      case 'Article':
        return `${langPrefix}/article/${uid}`;
      case 'PRISMIC_Page_category':
        return `${langPrefix}/category/${uid}`;
      case 'PRISMIC_Regions_of_iceland':
        return `${langPrefix}/region/${uid}`;
      case 'PRISMIC_Academy_course':
        return `${langPrefix}/course/${uid}`;
      case 'PRISMIC__ExternalLink':
      case 'PRISMIC__ImageLink':
        return fixRelative(url);
      case 'PRISMIC_A_o_page':
       return `${langPrefix}/a-o/${uid}`;
      default:
        return `${langPrefix}/${uid ? uid : ''}`;
    }
  }

  // console.warn('Missing typename', doc);
  return '/';
}
