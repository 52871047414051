
const targetLanguage = process.env.GATSBY_TARGET_LANGUAGE;

function fixRelative(url) {
  const lowered = url.toLocaleLowerCase();

  // Prismic UI will allow OR add https:// for relative links in their UI
  if (lowered.startsWith('https:///')) {
    return url.substr('https://'.length);
  }

  if (lowered.startsWith('http:///')) {
    return url.substr('http://'.length);
  }

  return url;
}
exports.fixRelative = fixRelative;

exports.linkResolver = function linkResolver(doc) {

  if (doc.link_type) {
    switch (doc.link_type) {
      case 'Web':
        return fixRelative(doc.url);
      case 'Media':
        return fixRelative(doc.url);
      default:
        if (doc.url) {
          return fixRelative(doc.url);
        }
    }
  }

  if (doc.type) {
    let lang = doc.lang === 'en-us' ? '' : doc.lang;

    if (targetLanguage) {
      lang = '';
    }

    switch (doc.type) {
      case 'home':
        return `/${lang}`;
      case 'page_content':
        return `${lang}/page/${doc.uid}`;
      case 'article':
        return `${lang}/article/${doc.uid}`;
      case 'page_category':
        return `${lang}/category/${doc.uid}`;
      case 'regions_of_iceland':
        return `${lang}/region/${doc.uid}`;
      case 'academy_course':
        return `${lang}/course/${doc.uid}`;
      case 'a_o_page':
        return `${lang}/a-o/${doc.uid}`;
      default:
        if (doc.uid) {
          return `${lang}/${doc.uid}`;
        }

        return `/${doc.type}`;
    }
  }

  return '/';
};
