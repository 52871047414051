import React from 'react';
import createLink from 'utils/createLink';
import { get, isEmpty } from 'lodash';

import { Articles } from 'components/search/Articles';
import { SearchCard } from 'components/search/SearchCard';

import RichText from 'components/rich-text/RichText';
import { PrismicImage } from 'components/prismic-image/PrismicImage';

interface IProps {
  data: any;
  title: string;
}

export const SearchArticles = ({ data, title }: IProps) => {

  const renderMenuCard = ({ article_nav_link: item }: any) => {
    if (item === null || item.image == null) { return; }

    const heading = get(item, 'title', []);
    const category = get(item, 'category.title', []);
    const image = !isEmpty(item.image.small_thumb_x1) ?
      <PrismicImage image={item.image} view="small_thumb" /> : undefined;

    return (
      <SearchCard
        key={item._meta.uid}
        link={createLink(item)}
        heading={RichText.asText(heading)}
        category={RichText.asText(category)}
        image={image}
      />
    );
  };

  return (
    <Articles heading={title}>
      {data.map(renderMenuCard)}
    </Articles>
  );
};
