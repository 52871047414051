import React from 'react';

interface IProps {
  staticUrl: string;
  type: 'menu' | 'footer' | 'sidebar';
  name: string;
  icon: React.ReactElement<any>;
  className?: string;
  hideOnSidebar?: boolean;
}

export const SocialItem = ({
    staticUrl,
    type,
    name,
    icon,
    className,
    hideOnSidebar,
  }: IProps) => {

  const createUrls = () => {
    const urls: any = {};
    const url = window.location.href;
    const encodedUrl = encodeURI(url);

    urls.facebook = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
    urls.twitter = `https://twitter.com/intent/tweet?text=${encodedUrl}`;
    urls.pinterest = `https://pinterest.com/pin/create/button/?url=&media=${encodedUrl}`;

    return urls;
  };

  const onClick = (link: string) => {
    const urls = createUrls();

    window.open(urls[link], '_blank', 'width=600,height=300');
  };

  const renderLink = () => {

    if (type === 'sidebar') {
      return (
        <button
          title={`Share on ${name}`}
          onClick={() => onClick(name)}
          className={className}
        >
          {icon}
        </button>
      );
    } else {
      if (staticUrl === '') { return null; }
      return (
        <a href={staticUrl} className={className}>{icon}</a>
      );
    }
  };

  return (
    <>
      {type === 'sidebar' && hideOnSidebar === true ? null : renderLink()}
    </>
  );
};
