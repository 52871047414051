import React, { useEffect, useContext } from 'react';
import Helmet from 'react-helmet';

import { helmet } from 'utils/helmet';
import { Header } from 'components/header/Header';
import { PageTransition } from 'components/page-transition/PageTransition';
import { Devtools } from 'components/devtools/Devtools';

import { FooterContainer } from 'containers/footer/Footer';
import { UIContext } from 'context/ui';
import { Consent } from 'components/consent/Consent';

import s from './AppLayout.scss';

interface IProps {
  children: React.ReactNode;
  navigation: any;
  location: {
    pathname: string;
  };
}

const isDev = process.env.NODE_ENV === 'development';
// const isKranavatnEnv = process.env.GATSBY_KRANAVATN === 'true';
const isKranavatnEnv = process.env.GATSBY_KRANAVATN === 'true';

export default ({ children, location, navigation }: IProps) => {
  const { setHeaderVisible, setFooterVisible } = useContext<any>(UIContext);

  useEffect(() => {
    const isKranavatn = location.pathname.includes('kranavatn') || isKranavatnEnv;

    setHeaderVisible(!isKranavatn);
    setFooterVisible(!isKranavatn);

  }, [location]);

  useEffect(() => {
    // siteimprove reach/router hack for aria label
    // siteimprove iframe title hack
    const wrapper = document.body.querySelector(
      'div[tabindex="-1"][role="group"][style]',
    );

    const iframe = document.documentElement.querySelector(
      'iframe[src*="inspiredbyiceland.prismic.io/toolbar/bootstrap"]',
    );

    if (!wrapper || !iframe) {
      return;
    }

    wrapper.setAttribute('aria-label', 'Main content section');
    iframe.setAttribute('title', 'Prismic toolbar');

  }, []);

  return (
  <div className={s.layout}>
    <Helmet {...helmet} />
      <PageTransition location={location}>
        <Header navigation={navigation} />
        <main id="content">
          {children}
        </main>
        <FooterContainer navigation={navigation} />
      </PageTransition>

    <Consent moreLink="/page/privacy-policy" />

    {isDev && <Devtools />}
  </div>
);

};
