import React, { Children, cloneElement, useContext, useState, useEffect } from 'react';
import { UIContext } from 'context/ui';

import { MenuContent } from './MenuContent';

import s from './Navigation.scss';

interface IProps {
  children: React.ReactNode;
  footer: React.ReactElement<any>;
  heading: string;
}

export const Navigation = ({ children, heading, footer }: IProps) => {
  const { searchOpen, menuOpen } = useContext<any>(UIContext);
  const [ tabIndex, setIndex ] = useState(-1);

  useEffect(() => {
    setIndex(menuOpen ? 0 : -1);
  }, [menuOpen]);

  if (searchOpen) { return null; }

  return (
    <MenuContent heading={heading} theme="light" transition>
      <div className={s.navigation}>
        <div className={s.navigation__listWrap}>
          <ul className={s.navigation__list} role="navigation" tabIndex={tabIndex}>
            {Children.map(children, (child, i) => {
              if (child === null) { return; }
              return (
                <li className={s.navigation__item} key={`${s.navigation}-${i}`}>
                  {cloneElement(child as React.ReactElement<any>, {
                    className: s.navigation__link,
                  })}
                </li>
              );
            })}
          </ul>
        </div>
        <div className={s.navigation__footer}>
          {footer}
        </div>
      </div>
    </MenuContent>
  );
};
