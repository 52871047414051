module.exports = {
  'en-us': {
    path: 'en-us',
    display: 'EN',
    fullDisplay: 'English',
    default: true,
    moment: 'en-gb',
  },
  is: {
    path: 'is',
    display: 'IS',
    fullDisplay: 'Íslenska',
    moment: 'is',
  },
  'sv-se': {
    path: 'sv-se',
    display: 'SE',
    fullDisplay: 'Svenska',
    moment: 'sv-se',
  },
  'de-de': {
    path: 'de-de',
    display: 'DE',
    fullDisplay: 'Deutsche',
    moment: 'de-de',
  },
};
