import React, { cloneElement, Children } from 'react';

import s from './FooterNav.scss';

interface IProps {
  children: React.ReactNode;
  type: 'menu' | 'footer';
}

export const FooterNav = ({ children, type }: IProps) => (
  <ul className={s('footerNav', type)}>
    {Children.map(children, (child, i) => {
      if (child === null) { return; }
      return (
      <li className={s.footerNav__item} key={`${s.navigation}-${i}`}>
        {cloneElement(child as React.ReactElement<any>, {
          className: s.footerNav__link,
        })}
      </li>
      );
    })}
  </ul>
);
