import React from 'react';

import s from './Categories.scss';

export const Categories = (props: { children?: React.ReactNode }) => {

  return (
    <div className={s.categories}>
      {props.children}
    </div>
  );
};

interface IProps {
  name: string;
  count: number;
  slug: string;
  prismicId: string;
  filtering?: any;
}

export const Category = ({ name, count, slug, prismicId, filtering }: IProps) => {

  let Icon;
  try {
    Icon = require(`assets/svg/icons/categories/${slug}.svg`);
  } catch {
    Icon = undefined;
  }

  return (
    <div className={s.category}>
      <div className={s.category__inner}>
        <span className={s.category__count}>{count}</span>
        <div className={s.category__icon}>
          {Icon && (<Icon />)}
        </div>
        <span className={s.category__label}>
        <button className={s.category__link} onClick={() => { filtering(prismicId); }}>{name}</button>
        </span>
      </div>
    </div>
  );
};
