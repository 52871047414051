/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

const React = require('react');
const { registerLinkResolver } = require('gatsby-source-prismic-graphql');
const { IntlProvider, addLocaleData } = require('react-intl');
const { linkResolver } = require('./src/utils/linkResolver');
const { NavigationProvider } = require('context/navigation');
const { LocaleProvider } = require('context/localization');
const { PageElementContainer } = require('containers/page-element/PageElement');
const { gsap } = require('gsap');
const { CSSPlugin } = require('gsap/CSSPlugin');
const { Draggable } = require('gsap/Draggable');
const { ScrollToPlugin } = require('gsap/ScrollToPlugin');

const { UIProvider } = require('context/ui');

// Locale data
const enData = require('react-intl/locale-data/en');
const isData = require('react-intl/locale-data/is');
const seData = require('react-intl/locale-data/sv');
const deData = require('react-intl/locale-data/de');

// Messages
const en = require('localization/en');
const is = require('localization/is');
const se = require('localization/se');
const de = require('localization/de');

const messages = { 'en-us': en, is, 'sv-se': se, 'de-de': de };

addLocaleData([...enData, ...isData, ...seData, ...deData]);

registerLinkResolver(linkResolver);
gsap.registerPlugin(ScrollToPlugin, Draggable, CSSPlugin);

exports.onClientEntry = () => {
  require('intersection-observer');
  require('focus-visible');
}

exports.wrapPageElement = ({ element, props: { pageContext: { lang, navigation, alternateLanguages, prefix } } }) => (
  <IntlProvider defaultLocale="en-us" locale={lang || 'en-us'} messages={messages[lang]}>
    <PageElementContainer lang={lang} navigation={navigation} alternateLanguages={alternateLanguages} prefix={prefix}>
      {element}
    </PageElementContainer>
  </IntlProvider>
)

exports.wrapRootElement = ({ element }) => (
  <LocaleProvider>
    <UIProvider>
      <NavigationProvider>
        {element}
      </NavigationProvider>
    </UIProvider>
  </LocaleProvider>
)
// page transitions
exports.shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition,
}) => {

    const transitionDelay = 750;

    // need to return out of this if is article navigation also
    if (location.hash) return; // don't scroll if its a hash link
    if (location.action === 'PUSH') {

      window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
    } else {
      const savedPosition = getSavedScrollPosition(location)
      window.setTimeout(
        () => window.scrollTo(...(savedPosition || [0, 0])),
        transitionDelay,
      );
  };
  return false;
};
