import * as React from 'react';

import { Container } from 'components/layout/Container';

import s from './Segment.scss';

interface IProps {
  children: React.ReactNode;
  container?: boolean;
  className?: string;
  label?: string;
}

export const Segment = ({ children, container, className, label }: IProps) => {
  const content = container ? <Container>{children}</Container> : children;

  return (
    <div className={s('segment', className)} aria-label={label}>
      {content}
    </div>
  );
};

Segment.defaultProps = {
  container: false,
};
