import React, { cloneElement } from 'react';

import { Link } from 'components/link/Link';
import { Row } from 'components/layout/Row';

import Arrow from 'assets/svg/icons/arrow.svg';

import s from './Callout.scss';

interface IProps {
  title: string;
  link: string;
}

export const Callout = ({ title, link }: IProps) => {

  return (
    <div className={s.callout}>
      <h2 className={s.callout__heading}>
        <Link className={s.callout__link} to={link}>{title}</Link>
      </h2>
      <span className={s.callout__icon}><Arrow /></span>
    </div>
  );
};
