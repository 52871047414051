import React from 'react';
import path from 'path';
import { Location, WindowLocation } from '@reach/router';

import languages from 'localization/languages';

interface IProps {
  children: React.ReactNode;
}

interface IProviderProps extends IProps {
  location: WindowLocation;
}

interface ILanguage {
  uid: string;
  lang: string;
}

export const LocaleContext = React.createContext({
  localizedPath: (_: string, __: Localization.ILanguage) => null,
});

export const Provider = ({ children }: IProviderProps) => {
  const [alternateLanguages, setAlternateLanguages] = React.useState([]);
  const [currentPath, setCurrentPath] = React.useState('');

  const localizedPath = (selectedLang: string, currentLang: Localization.ILanguage) => {
    const alternateLanguage = alternateLanguages.find((item: ILanguage) => item.lang === selectedLang);

    // Selecting the current lang is a no-op
    if (currentLang.path === selectedLang) {
      return null;
    }

    // A version for the language selected does not exist in prismic. Redirect to lang frontpage
    if (!alternateLanguage) {
      const langMeta: Localization.ILanguage = languages[selectedLang];

      // language exists and isn't the default one—redirect to it's frontpage
      if (langMeta && !langMeta.default) {
        return `/${selectedLang}/`;
      }

      return '/';
    }

    const { lang, uid } = alternateLanguage;
    const language: Localization.ILanguage = languages[lang];

    return language.default ? path.join(currentPath || '', uid || '') : path.join(lang, currentPath, uid || '');
  };

  return (
      <LocaleContext.Provider value={{ localizedPath, setAlternateLanguages, setCurrentPath }}>
        {children}
      </LocaleContext.Provider>
  );
};

export const LocaleProvider = (props: IProps) => (
  <Location>
    {(locationProps) => <Provider {...locationProps} {...props} />}
  </Location>
);
