import React from 'react';

import s from './Alert.scss';

interface IProps {
  visible?: boolean;
  children: React.ReactNode;
  label?: string;
}

export const Alert = ({ visible, children, label = 'GDPR consent' }: IProps) => {

  if (!visible) {
    return null;
  }

  return (
    <div className={s('alert')} tabIndex={0} aria-label={label}>
      {children}
    </div>
  );
};

Alert.defaultProps = {
  visible: true,
};
