import React from 'react';

import { FormattedMessage } from 'react-intl';

import { SearchWrapper } from 'components/menu/Search';
import { SearchContainer } from 'containers/search/Search';

interface IProps {
  articles: React.ReactElement<any>;
  cta: React.ReactElement<any>;
}

export const SearchMenu = ({ articles, cta }: IProps) => {

  return (
    <FormattedMessage id="search" defaultMessage="Search">
      {(searchString) => (
        <SearchWrapper heading={searchString as string}>
          <SearchContainer isMenu articles={articles} cta={cta} />
        </SearchWrapper>
      )}
    </FormattedMessage>
  );
};
