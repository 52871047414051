import React from 'react';

import Facebook from 'assets/svg/icons/social/facebook.svg';
import Twitter from 'assets/svg/icons/social/twitter.svg';
import Instagram from 'assets/svg/icons/social/instagram.svg';
import YouTube from 'assets/svg/icons/social/youtube.svg';
import Pinterest from 'assets/svg/icons/social/pinterest.svg';

import { Social } from 'components/social/Social';
import { SocialItem } from 'components/social/SocialItem';

interface IProps {
  type: 'menu' | 'footer' | 'sidebar';
  blended?: boolean;
  facebookLink: string;
  twitterLink: string;
  instagramLink?: string;
  youtubeLink?: string;
  pinterestLink: string;
}

export const SocialContainer = ({
    type,
    blended,
    facebookLink,
    twitterLink,
    instagramLink,
    youtubeLink,
    pinterestLink,
  }: IProps) => {

  return (
    <Social type={type} blended={blended}>
      <SocialItem staticUrl={facebookLink} type={type} name="facebook" icon={<Facebook />}/>
      <SocialItem staticUrl={twitterLink} type={type} name="twitter" icon={<Twitter />}/>
      {instagramLink && (
        <SocialItem
          staticUrl={instagramLink}
          type={type}
          hideOnSidebar={type === 'sidebar'}
          name="instagram"
          icon={<Instagram />}
        />)}
      {youtubeLink && (
        <SocialItem
          staticUrl={youtubeLink}
          type={type}
          hideOnSidebar={type === 'sidebar'}
          name="instagram"
          icon={<YouTube />}
        />)}
      <SocialItem staticUrl={pinterestLink} type={type} name="pinterest" icon={<Pinterest />}/>
    </Social>
  );
};
