import React from 'react';

interface IProps {
  children: React.ReactNode;
}

export const NavigationContext = React.createContext({});

export const NavigationProvider = ({ children }: IProps) => {

  const [navigation, setNavigation] = React.useState({});

  return (
    <NavigationContext.Provider value={{ navigation, setNavigation }}>
      {children}
    </NavigationContext.Provider>
  );

};
