import React from 'react';
import { FormattedMessage } from 'react-intl';

import s from './MenuButton.scss';

interface IProps {
  onClick: () => void;
  close: boolean;
}

export const MenuButton = ({ onClick, close }: IProps) => {

  return (
    <button className={s('menuButton', { close })} onClick={onClick} tabIndex={0}>
      <FormattedMessage id="menu" defaultMessage="Menu">
            {(text) => <span className={s.menuButton__inner}>{text}</span>}
      </FormattedMessage>
    </button>
  );
};
