/* main menu wrapper for search */
import React, { useContext } from 'react';
import { UIContext } from 'context/ui';

import { MenuContent } from './MenuContent';

import s from './Search.scss';

interface IProps {
  children: React.ReactNode;
  heading: string;
}

export const SearchWrapper = ({ children, heading }: IProps) => {

  const { searchOpen } = useContext<any>(UIContext);

  if (!searchOpen) { return null; }

  return (
    <MenuContent theme="light" heading={heading}>
      <div className={s.search__content}>
        {children}
      </div>
    </MenuContent>
  );
};
