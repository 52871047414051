import React, { useState } from 'react';
import Observer from '@researchgate/react-intersection-observer';

interface IProps {
  children: React.ReactElement<any>;
  onEnter: () => void;
  onExit?: () => void;
  threshold: number;
  once: boolean;
}

export const ViewportEnter = ({ children, onEnter, onExit, threshold, once }: IProps) => {

  const [active, setActive] = useState<boolean>(false);

  const onChange = (event: { isIntersecting: any; }, unobserve: () => void) => {

    if (!active) {
      if (event.isIntersecting) {
        setActive(true);
        onEnter();
      }
    } else {
      if (onExit) {
        onExit();
        setActive(false);
      }

      if (once) {
        unobserve();
      }
    }
  };

  return (
    <Observer threshold={threshold} onChange={onChange}>
      {children}
    </Observer>
  );

};

ViewportEnter.defaultProps = {
  onEnter: () => null,
  onExit: () => null,
  threshold: 0.25,
  once: true,
};
