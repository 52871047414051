import React, { useState, useEffect, cloneElement, Children } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { get, isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { useKeyDown } from 'hooks/use-keydown';
import { withPreview } from 'gatsby-source-prismic-graphql';
import createLink from 'utils/createLink';

import { MenuCard } from 'components/menu/MenuCard';
import RichText from 'components/rich-text/RichText';
import { PrismicImage } from 'components/prismic-image/PrismicImage';
import { Categories } from 'components/search/Categories';

import s from './Search.scss';

interface IProps {
  data: any;
  prismic: any;
  children?: React.ReactElement<any>; // results
  isMenu: boolean; // is search in menu? (for css)
  categories: React.ReactElement<any>;
  articles: React.ReactElement<any>;
  cta: React.ReactElement<any>;
  location: any;
}

const query = graphql`
  query Search($fulltext: String, $cat: String) {
    prismic {
      _allDocuments(type_in: ["article"], fulltext: $fulltext) {
        edges {
          node {
            __typename
            _meta {
              uid
              id
            }
            ... on PRISMIC_Article {
              title
              image
              category {
                ... on PRISMIC_Article_category {
                  title
                }
              }
            }
          }
        }
      }
      allArticles(where: { category: $cat }) {
        totalCount
        edges {
          node {
            _meta {
              id
              uid
            }
            title
            image
            category {
              ... on PRISMIC_Article_category {
                title
                _meta {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;

function SearchWithData({ prismic, children, categories, articles, cta, isMenu, data }: any) {

  const keys =  useKeyDown();
  const [inputValue, setInputValue] = useState('');
  const [resultsVisible, setResultsVisible] = useState(false);
  const [filterResultVisible, setFilterResultVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onChange = (event: React.FormEvent<HTMLInputElement>) => {
    setInputValue(event.currentTarget.value);
  };

  const onSearch = (e: React.FormEvent<HTMLFormElement>) => {

    // TODO fix
    // setResultsVisible(true);
    // doSerch(inputValue);
  };

  const doSerch = (value: string) => {
    setIsLoading(true);
    if (data !== undefined) { data.prismic._allDocuments.edges = []; }
    if (data !== undefined) { data.prismic.allArticles.edges = []; }

    setFilterResultVisible(false);
    setResultsVisible(true);

    const getArticles = new Promise((resolve) => {
      resolve(prismic.load({ type_in: ['article'], fulltext: value}));
    });

    getArticles.then(() => {
      setIsLoading(false);
    });
  };

  const doSearchCategory = (catId: string) => {
    setIsLoading(true);
    if (data !== undefined) { data.prismic.allArticles.edges = []; }
    if (data !== undefined) { data.prismic._allDocuments.edges = []; }

    setResultsVisible(false);
    setFilterResultVisible(true);

    const getArticles = new Promise((resolve) => {
      resolve(prismic.load({ cat: catId }));
    });

    getArticles.then(() => {
      setIsLoading(false);
    });
  };

  const searchResults = get(data, 'prismic._allDocuments.edges', []);
  const filterResults = get(data, 'prismic.allArticles.edges', []);

  const renderResult = ({ node: item }: any) => {
    if (item === null || item.image === null) { return; }

    const image = !isEmpty(item.image.x1) ?
      <PrismicImage image={item.image} view="x1" /> : undefined;

    return(
      <MenuCard
        key={item._meta.id}
        heading={RichText.asText(item.title)}
        category={RichText.asText(item, 'category.title')}
        image={image}
        link={createLink(item)}
      />
    );
  };

  return (
    <div className={s('search', { isMenu })}>
      <form action="/articles" method="get" className={s.search__header} onSubmit={onSearch}>
        <FormattedMessage id="search-here" defaultMessage="Search here">
          {(placeholder) =>
            <input
              name="query"
              value={inputValue}
              onChange={onChange}
              className={s.search__input}
              aria-label={placeholder as string}
              placeholder={placeholder as string}
            />
          }
        </FormattedMessage>
      </form>

      {!resultsVisible && !filterResultVisible && (
        articles
      )}

      {resultsVisible && cloneElement(children as React.ReactElement<any>, {
          onClear: () => { setResultsVisible(false); setInputValue(''); },
          articleCount: searchResults.length || 0,
          isLoading,
        },
        searchResults.map(renderResult),
      )}

      {filterResultVisible && cloneElement(children as React.ReactElement<any>, {
          onClear: () => { setFilterResultVisible(false); setInputValue(''); },
          articleCount: filterResults.length || 0,
          isLoading,
        },
        filterResults.map(renderResult),
      )}

      <div className={s.search__cta}>
        {cta}
      </div>

    </div>
  );
}

export const Search = (props: IProps) => (
  <StaticQuery
    query={query}
    render={withPreview((data: any) => {
      return <SearchWithData {...props} data={data} />;
    }, query) as any}
  />
);
