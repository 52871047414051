import React, { useContext, useEffect } from 'react';
import { Location, WindowLocation } from '@reach/router';
import { NavigationContext } from 'context/navigation';
import { LocaleContext } from 'context/localization';

import { UIContext } from 'context/ui';

interface IPageElementContainerProps {
  lang: string;
  navigation: object;
  alternateLanguages: any;
  prefix: string;
  children: React.ReactNode;
}

interface IContainerProps extends IPageElementContainerProps {
  location: WindowLocation;
}

export const Container = ({ lang, navigation, children, alternateLanguages = [], prefix = '', location }: IContainerProps) => {

  const { setAlternateLanguages, setCurrentPath } = useContext<any>(LocaleContext);
  const { setNavigation } = useContext<any>(NavigationContext);
  const { closeAllMenus } = useContext<any>(UIContext);

  useEffect(() => {
    closeAllMenus();
    setCurrentPath(prefix);
    setAlternateLanguages(alternateLanguages);
  }, [location.pathname]);

  useEffect(() => {
    setAlternateLanguages(alternateLanguages);
    setNavigation(navigation);
  }, [lang]);

  return React.Children.toArray(children).map((c) => React.cloneElement(c as React.ReactElement<any>, { navigation }));
};

export const PageElementContainer = (props: IPageElementContainerProps) => (
  <Location>
    {(locationProps) => <Container {...locationProps} {...props} />}
  </Location>
);
