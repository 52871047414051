import React, { useContext } from 'react';

import { UIContext } from 'context/ui';

import s from './MenuContent.scss';

interface IProps {
  children: React.ReactNode;
  heading: string;
  theme: 'dark' | 'light';
  transition?: boolean;
}

export const MenuContent = ({ children, theme, heading, transition }: IProps) => {

  const { menuOpen } = useContext<any>(UIContext);

  return (
    <nav className={s('menuContent', theme, { transition, open: menuOpen })} aria-labelledby="menu-heading">
      <div className={s.menuContent__headingWrap}>
        <h1 id="menu-heading" className={s.menuContent__heading}>{heading}</h1>
      </div>
      <div className={s.menuContent__content}>
        {children}
      </div>
    </nav>
  );
};
