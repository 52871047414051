import React from 'react';

import s from './Row.scss';

interface IProps {
  children: React.ReactNode;
  className?: string;
  alignItems?: 'start' | 'center' | 'end' | 'stretch';
}

export const Row = ({ children, alignItems, className }: IProps) => (
  <div className={s('row', alignItems, className )}>
    {children}
  </div>
);

Row.defaultProps = {
  alignItems: 'stretch',
};
